<template>
  <div style="height: auto;background-color: #fff;border: 10px solid #edeff2;">
    <a-spin :spinning="spinning">
      <div style="padding: 20px; background-color: #fff">
        <a-table
            :columns="columns"
            style="min-height: 500px; background-color: #fff"
            :data-source="HeplTable"
            :rowKey="(record) => record.id"
            :pagination="false"
        >
          <span slot="conventionCount" slot-scope="text, record">
            <a @click="JumpTo(record)">{{ text }}</a>
          </span>
        </a-table>
        <!-- table框的分页 -->
        <div
            style="
            margin-top: 28px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <span
              style="font-size: medium; color: #929292; font-size: 14px; bottom: 3%"
          >
            共{{ pagination.total }}条记录 第 {{ pagination.pageNum }} /
            {{ Math.ceil(pagination.total / pagination.pageSize) }} 页
          </span>
          <a-pagination
              style="float: right"
              show-quick-jumper
              show-size-changer
              :page-size.sync="pagination.pageSize"
              :total="pagination.total"
              v-model="pagination.current"
              @change="pageChange"
              @showSizeChange="pageChange"
          />
        </div>
    </div>
    </a-spin>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
import {getHomePageVisitedRecords} from "@/service/customerManagement_api";
export default {
  data(){
      return {
        modalShow:false,
        timeVal: [],
        pagination: {
          //存储大table的分页
          total: 0,
          current: 1,
          pageSize: 10,
          pageNum: 1,
        },
        columns: [
          {
            title: "类型",
            dataIndex: "certificationStatusString",
            key: "certificationStatusString",
            // align: "center",
            // width: "8%",
          },
          {
            title: "名称",
            dataIndex: "name",
            key: "name",
            // align: "center",
            // width: "15%",
          },
          {
            title: "绑定时间",
            dataIndex: "createdTime",
            key: "createdTime",
            // align: "center",
            // width: "28%",
          },
          {
            title: "会议总数量",
            dataIndex: "conventionCount",
            key: "conventionCount",
            // align: "center",
            scopedSlots: { customRender: "conventionCount" },
          },
        ],
        HeplTable: [],
        StartTime: "",
        EndTime: "",
        spinning:false,
        setPriceForm:{},
        labelCol: { span: 4},
        wrapperCol: { span: 18 },
        reviseFormRules: {
          dingjia: [{ required: true, message: '请输入描述', trigger: 'blur' }],
        },
      };
  },
  created() {
    this.getTable();
    this.getmenu()
  },
  methods: {
    JumpTo(record){
      // console.log("记录",record.memberId)
      // PathmeetingManagement
      router.push({
        path:"/PathmeetingManagement",
        query: {
          memberId: record.memberId // 打包以后location变成base
        }
      })
    },
    getmenu(){
      this.$store.dispatch("manageHeaderTitleFTitle", "客户管理");
      this.$store.dispatch("manageHeaderTitleSTitle", "我的客户");
    },
    // 日期
    onChange_range_picker(date, dateString) {
      this.StartTime = dateString[0];
      this.EndTime = dateString[1];
      this.pagination.pageSize = 10;
      this.pagination.pageNum = 1;
      this.pagination.current = 1;
      this.getTable();
    },
    // 分页
    pageChange(page_no, page_size) {
      this.pagination.pageNum = page_no;
      this.pagination.pageSize = page_size;
      this.getTable();
    },
    // 获取列表
    async getTable() {
      let data = {};
      data = {
        pageNo: this.pagination.pageNum,
        pageSize: this.pagination.pageSize,
      };
        const response = await getHomePageVisitedRecords(data);
        if (response.code === 0) {
          this.HeplTable = response.data;
          this.pagination.total = response.count;
        } else {
          this.$message.warning(response.message);
        }

      // 反正也没事做
      function getMaxArryNum(arr) {
	      return Math.max(...arr)
      }
      getMaxArryNum([1,2,3,4,5,6])

    },
    SetPrice(){
      this.modalShow = true
    }
  }
}
</script>

<style>
.search {
  background-color: #fff;
  width: 100%;
  height: 64px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
::v-deep .ant-table-thead > tr > th {
  font-weight: bold;
}
</style>
